/**
 * @Desc 防抖函数
 * @param fn
 * @param delay
 * @param dep
 * @returns {*}
 */
import { debounce } from 'lodash'

import { useCallback, useEffect, useRef } from 'react'

// const useDebounce = (fn, delay, dep = []) => {
//   const { current } = useRef({ fn, timer: null })
//   useEffect(
//     function () {
//       current.fn = fn
//     },
//     [fn]
//   )

//   return useCallback(function f(...args) {
//     if (current.timer) {
//       clearTimeout(current.timer)
//     }
//     current.timer = setTimeout(() => {
//       current.fn.call(this, ...args)
//     }, delay)
//   }, dep)
// }

const useDebounce = (callback, wait, options) => {
  const createDebouncedCallback = useCallback(
    (function_) => {
      return debounce(function_, wait, options)
    },
    [wait, options]
  )

  const callbackRef = useRef(callback)
  const debouncedCallbackRef = useRef(createDebouncedCallback(callback))

  useEffect(() => {
    callbackRef.current = callback
  })

  useEffect(() => {
    debouncedCallbackRef.current = createDebouncedCallback(callbackRef.current)
  }, [wait, options, createDebouncedCallback])

  return debouncedCallbackRef.current
}
/* 
function useDebounce<T extends (...args: any[]) => unknown>(
  callback: T,
  wait: number,
  options?: {}
): DebouncedFunc<T> {
  const createDebouncedCallback = useCallback(
    (function_: T): DebouncedFunc<T> => {
      return debounce(function_, wait, options);
    },
    [wait, options]
  );

  const callbackRef = useRef<T>(callback);
  const debouncedCallbackRef = useRef<DebouncedFunc<T>>(
    createDebouncedCallback(callback)
  );

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    debouncedCallbackRef.current = createDebouncedCallback(callbackRef.current);
  }, [wait, options, createDebouncedCallback]);

  return debouncedCallbackRef.current;
}

*/

export default useDebounce
