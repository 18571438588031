import PropTypes from 'prop-types'

import { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Link } from '@mui/material'
// @mui
import { useTheme } from '@mui/material/styles'

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const theme = useTheme()

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        color: PRIMARY_MAIN,
        ...sx
      }}
      {...other}
    >
      <svg
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="currentColor"
      >
        <g>
          <path d="M186.317 517.988c29.434 51.911 72.588 99.095 122.764 133.975 59.917 41.648 126.232 63.715 191.825 63.849l0 0.004c0.085 0 0.172-0.002 0.256-0.002 0.083 0 0.172 0.002 0.255 0.002l0-0.004c65.592-0.134 131.909-22.2 191.826-63.849 50.177-34.88 93.328-82.063 122.765-133.975 28.887-1.754 52.672-13.536 69.122-34.336 16.342-20.667 24.628-48.887 24.628-83.871 0-42.138-13.004-77.217-35.688-100.06 0 0-17.875-16.132-29.479-20.996-18.77-71.778-61.351-138.955-120.94-190.39C657.653 31.373 576.904 0 496.28 0c-80.278 0-159.353 31.53-222.661 88.784-56.138 50.767-96.752 117.961-115.335 190.41-11.3 4.862-21.366 11.805-30.026 20.525-22.684 22.842-35.689 57.924-35.689 100.06 0 34.984 8.287 63.202 24.63 83.871C133.647 504.452 157.431 516.234 186.317 517.988zM184.109 320.015l11.856-3.068c32.577-3.543 63.169-9.7 91.215-17.225 84.396-22.645 145.636-57.707 167.767-71.531 5.939-3.709 12.613-3.893 16.665 0.208 9.25 9.359 21.119 29.679-0.633 62.239-0.787 0.925-1.486 1.93-2.083 2.998-0.059 0.084-0.11 0.164-0.171 0.248-0.006 0.008-0.045 0.116-0.105 0.29-0.806 1.56-1.396 3.242-1.733 5.019-0.154 0.441-0.258 0.741-0.266 0.755 0.045 0.036 0.088 0.072 0.135 0.107-0.125 0.861-0.208 1.739-0.208 2.633 0 10.301 8.352 18.655 18.655 18.655 0.875 0 1.733-0.082 2.58-0.199 0.004 0.002 0.029 0.024 0.033 0.022 70.397-10.502 108.697-64.953 128.313-109.133 4.184-9.416 12.376-8.197 17.262-1.946 37.441 47.887 70.148 73.213 96.84 86.236 28.938 14.121 45.315 17.273 76.124 20.625l11.854 3.068c41.229 10.664 44.434 63.75 44.434 79.767 0 47.328-18.271 71.325-54.307 71.325-0.828 0-2.054-0.096-3.35-0.198l-16.74-1.26-7.146 13.864c-45.938 89.116-153.108 185.019-279.941 185.19-126.833-0.172-234.003-96.076-279.942-185.19l-7.146-13.864-16.74 1.26c-1.295 0.102-2.522 0.198-3.349 0.198-36.037 0-54.307-23.997-54.307-71.325C139.676 383.764 142.881 330.679 184.109 320.015z" />
        </g>
        <g>
          <path d="M387.251 471.262c29.03 0 52.64-23.563 52.64-52.684 0-29.071-23.609-52.676-52.64-52.676-29.113 0-52.714 23.604-52.714 52.676C334.533 447.625 358.138 471.262 387.251 471.262zM398.048 398.083c14.92 0 26.957 12.112 26.957 26.993 0 14.956-12.037 27.071-26.957 27.071-14.918 0-26.998-12.115-26.998-27.071C371.054 410.195 383.13 398.083 398.048 398.083z" />
        </g>
        <g>
          <path d="M615.074 471.262c29.112 0 52.713-23.637 52.713-52.684 0-29.071-23.602-52.676-52.713-52.676-29.029 0-52.641 23.604-52.641 52.676C562.434 447.699 586.045 471.262 615.074 471.262zM604.276 398.083c14.918 0 26.999 12.112 26.999 26.993 0 14.956-12.079 27.071-26.999 27.071-14.921 0-26.956-12.115-26.956-27.071C577.317 410.195 589.355 398.083 604.276 398.083z" />
        </g>
        <g>
          <path d="M712.403 730.268c-16.45 0-65.785 0.126-76.675 0.157-0.204-0.007-0.405-0.033-0.609-0.033-0.228 0-0.45 0.026-0.675 0.033-0.563 0.002-0.892 0.004-0.892 0.004l-0.081 0.047c-7.982 0.53-14.905 4.87-18.99 11.231l-0.045 0.027c0 0-0.266 0.465-0.758 1.298-0.042 0.077-0.087 0.149-0.129 0.226-8.978 15.295-112.803 142.35-112.803 142.35s-103.825-127.055-112.798-142.35c-0.044-0.078-0.094-0.15-0.134-0.226-0.49-0.833-0.758-1.298-0.758-1.298l-0.047-0.027c-4.076-6.362-11.004-10.701-18.988-11.231l-0.077-0.047c0 0-0.331-0.002-0.895-0.004-0.225-0.007-0.445-0.033-0.674-0.033-0.204 0-0.403 0.024-0.609 0.033-10.891-0.031-60.223-0.157-76.674-0.157-74.247 0-173.669 67.611-173.669 153.773 0 86.167 0 102.303 0 102.303l0.144 0c1.636 19.913 18.268 35.565 38.561 35.565l693.243 0c20.289 0 36.923-15.652 38.56-35.565l0.144 0c0 0 0-16.136 0-102.303C886.072 797.879 786.646 730.268 712.403 730.268z" />
        </g>
        <g>
          <path d="M491.819 758.367m-14.337 0a14.337 14.337 0 1 0 28.674 0 14.337 14.337 0 1 0-28.674 0Z" />
        </g>
        <g>
          <path d="M491.819 808.204m-14.337 0a14.337 14.337 0 1 0 28.674 0 14.337 14.337 0 1 0-28.674 0Z" />
        </g>
      </svg>
    </Box>
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return (
    <Link
      to="/"
      component={RouterLink}
      sx={{ display: 'contents' }}
    >
      {logo}
    </Link>
  )
})

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool
}

export default Logo
