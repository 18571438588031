export const TOKEN_KEY = 'School-AccessToken'

export const IS_WORK = import.meta.env.VITE_WORK === 'work'

console.log('IS_WORK', IS_WORK)

export const JOKE_MASK = 'Joke'

// upload server api timeout
export const UPLOAD_TIMEOUT = 60000

export const SEX = {
  0: '女',
  1: '男'
}

export const STATUS = {
  N: '正常',
  D: '已删除'
}

export const LEVEL = {
  0: '幼儿园',
  1: '小学',
  2: '初中',
  3: '高中',
  4: '大学'
}

export const MEMBER_TYPE = {
  1: '学生',
  2: '老师'
}

const arrayAvatars = []
let i = 1
while (i < 25) {
  arrayAvatars.push({
    label: `avatar_${i}.jpg`,
    src: `/assets/images/avatars/avatar_${i}.jpg`
  })
  i++
}

export const AVATAR_CARTOON = arrayAvatars

export const SEXS = [
  { label: 'Male', value: '1' },
  { label: 'Female', value: '0' }
]

export const SEXS_DRAWER = [
  { label: 'All', value: '' },
  { label: 'Male', value: '1' },
  { label: 'Female', value: '0' }
]

export const MEMBERTYPES = [
  { label: 'Student', value: '1' },
  { label: 'Teacher', value: '2' }
]

export const LEVELS = [
  { label: '幼儿园', value: '0' },
  { label: '小学', value: '1' },
  { label: '初中', value: '2' },
  { label: '高中', value: '3' },
  { label: '大学', value: '4' }
]

export const ROLES = ['kindergarten', 'primarySchool', 'juniorHighschool', 'seniorHighschool', 'university']

export const FAMILY_NAMES = [
  '罗',
  '梁',
  '宋',
  '唐',
  '许',
  '韩',
  '冯',
  '邓',
  '曹',
  '彭',
  '曾',
  '萧',
  '田',
  '董',
  '袁',
  '潘',
  '于',
  '蒋',
  '蔡',
  '余',
  '杜',
  '叶',
  '程',
  '苏',
  '魏',
  '吕',
  '丁',
  '任',
  '沈',
  '姚',
  '卢',
  '姜',
  '崔',
  '钟',
  '谭',
  '陆',
  '汪',
  '范',
  '金',
  '石',
  '廖',
  '贾',
  '夏',
  '韦',
  '付',
  '方',
  '白',
  '邹',
  '孟',
  '熊',
  '秦',
  '邱',
  '江',
  '尹',
  '薛',
  '闫',
  '段',
  '雷',
  '侯',
  '龙',
  '史',
  '陶',
  '黎',
  '贺',
  '顾',
  '毛',
  '郝',
  '龚',
  '邵',
  '万',
  '钱',
  '严',
  '覃',
  '武',
  '戴',
  '莫',
  '孔',
  '向',
  '汤'
]

export const SOCIALS = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#1877F2'
  },
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'ant-design:instagram-filled',
    color: '#E02D69'
  },
  {
    value: 'linkedin',
    name: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#007EBB'
  },
  {
    value: 'twitter',
    name: 'Twitter',
    icon: 'eva:twitter-fill',
    color: '#00AAEC'
  }
]
