import { useState } from 'react'

// @mui
import { MenuItem, Stack } from '@mui/material'

import { IconButtonAnimate } from '../../../components/animate'
// components
import Image from '../../../components/image'
import MenuPopover from '../../../components/menu-popover'
// locales
import { useLocales } from '../../../locales'

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { allLangs, currentLang, onChangeLang } = useLocales()

  const [openPopover, setOpenPopover] = useState(null)

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang)
    handleClosePopover()
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: 'action.selected'
          })
        }}
      >
        <Image
          disabledEffect
          src={currentLang.icon}
          alt={currentLang.label}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 180 }}
      >
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  )
}
