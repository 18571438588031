import PropTypes from 'prop-types'
import sha256 from 'sha256'

import { createContext, useCallback, useEffect, useReducer } from 'react'

import { TOKEN_KEY } from '@utils/constant'

// utils
import { loadAccountInfoApi, loginApi } from '@api/api'


import { isValidToken, setSession } from './utils'

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null
}

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user
    }
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user
    }
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user
    }
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null
    }
  }

  return state
}

// ----------------------------------------------------------------------

export const AuthContext = createContext(null)

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node
}

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const initialize = useCallback(async () => {
    try {
      const accessToken = typeof window !== 'undefined' ? localStorage.getItem(TOKEN_KEY) : ''

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken)

        const response = await loadAccountInfoApi()

        const { user } = response.data

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user
          }
        })
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    } catch (error) {
      console.error(error)
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null
        }
      })
    }
  }, [])

  useEffect(() => {
    initialize()
  }, [initialize])

  // LOGIN
  const login = async (email, password) => {
    const response = await loginApi({
      email,
      password: sha256(password)
    })
    /* 
		{
    "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI4ODY0YzcxNy01ODdkLTQ3MmEtOTI5YS04ZTVmMjk4MDI0ZGEtMCIsImlhdCI6MTY5NTkwMjg4NywiZXhwIjoxNjk2MTYyMDg3fQ.dnUeDVkfzZ-bypNgC0qpybR1ApD94TjhPCsAkkp3CLs",
    "user": {
        "id": "8864c717-587d-472a-929a-8e5f298024da-0",
        "displayName": "Jaydon Frankie",
        "email": "demo@minimals.cc",
        "password": "demo1234",
        "photoURL": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_default.jpg",
        "phoneNumber": "+40 777666555",
        "country": "United States",
        "address": "90210 Broadway Blvd",
        "state": "California",
        "city": "San Francisco",
        "zipCode": "94116",
        "about": "Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.",
        "role": "admin",
        "isPublic": true
    }
}
		*/
    console.log('response', response)
    if (response.code !== 0) {
      throw new Error(`Login Error,${response.msg}`)
      // return false
    }
    const { accessToken, user } = response.data

    setSession(accessToken)

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    })
  }



  // LOGOUT
  const logout = async () => {
    setSession(null)
    dispatch({
      type: 'LOGOUT'
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
