import { UPLOAD_TIMEOUT } from '@utils/constant'
import service from '@utils/request'



export function loginApi(data) {
  return service({
    url: '/account/login',
    method: 'post',
    data
  })
}

export function logoutApi() {
  return service({
    url: '/account/logout',
    method: 'get'
  })
}

export function registerApi(data) {
  return service({
    url: '/account/register',
    method: 'post',
    isFormData: true,
    data
  })
}

export function loadAccountInfoApi() {
  return service({
    url: '/account/info',
    method: 'get'
  })
}

export function loadMembers(params) {
  return service({
    url: `/members`,
    method: 'get',
    params
  })
}

export function loadMember(params) {
  return service({
    url: `/members/member`,
    method: 'get',
    params
  })
}

export function updateMember(data) {
  return service({
    url: `/members/member/update`,
    method: 'post',
    data
  })
}

export function createMember(data) {
  return service({
    url: `/members/member/create`,
    method: 'post',
    data
  })
}

export function deleteMember(data) {
  return service({
    url: `/members/member/delete`,
    method: 'post',
    data
  })
}

export function deleteMemberBatch(data) {
  return service({
    url: `/members/delete_batch`,
    method: 'post',
    data
  })
}

export function loadReadQuantitiesApi(data) {
  return service({
    url: `/read`,
    method: 'post',
    data
  })
}

/* 
   Read Quantity
*/

export function loadReadAllApi(params) {
  return service({
    url: `/read`,
    method: 'get',
    params
  })
}

export function loadReadAllMonthsApi(params) {
  return service({
    url: `/read/months`,
    method: 'get',
    params
  })
}

export function loadReadAllYearsApi(params) {
  return service({
    url: `/read/years`,
    method: 'get',
    params
  })
}

export function loadReadQuantitiesAllApi(data) {
  return service({
    url: `/read-quantity/all`,
    method: 'post',
    data
  })
}

export function loadReadQuantityYearsApi(params) {
  return service({
    url: `/read-quantity/years`,
    method: 'get',
    params
  })
}

export function loadReadQuantityApi(params) {
  return service({
    url: `/read-quantity/one`,
    method: 'get',
    params
  })
}

export function updateReadQuantityApi(data) {
  return service({
    url: `/read-quantity/one/update`,
    method: 'post',
    data
  })
}

export function createReadQuantityApi(data) {
  return service({
    url: `/read-quantity/one/create`,
    method: 'post',
    data
  })
}

export function deleteReadQuantityApi(data) {
  return service({
    url: `/read-quantity/one/delete`,
    method: 'post',
    data
  })
}

export function deleteReadQuantityBatchApi(data) {
  return service({
    url: `/read-quantity/delete_batch`,
    method: 'post',
    data
  })
}

/* 
members image
*/

export function deleteMembersImage(data) {
  return service({
    url: `/members/member/gallery/delete`,
    method: 'post',
    data
  })
}

export function updateMembersImage(data) {
  return service({
    url: `/members/member/gallery/update`,
    method: 'post',
    data
  })
}

export function updateMembersImageOrderApi(data) {
  return service({
    url: `/members/member/gallery/order`,
    method: 'post',
    data
  })
}

/* school */

export function loadSchoolsApi(params) {
  return service({
    url: `/schools`,
    method: 'get',
    params
  })
}

export function loadSchoolByLevelApi(params) {
  return service({
    url: `/schools/school/school-by-level`,
    method: 'get',
    params
  })
}

export function loadSchoolApi(params) {
  return service({
    url: `/schools/school`,
    method: 'get',
    params
  })
}

export function updateSchoolApi(data) {
  return service({
    url: `/schools/school/update`,
    method: 'post',
    data
  })
}

export function createSchoolApi(data) {
  return service({
    url: `/schools/school/create`,
    method: 'post',
    data
  })
}

export function deleteSchoolApi(data) {
  return service({
    url: `/schools/school/delete`,
    method: 'post',
    data
  })
}
export function renewSchoolApi(data) {
  return service({
    url: `/schools/school/renew`,
    method: 'post',
    data
  })
}

export function deleteBatchSchoolApi(data) {
  return service({
    url: `/schools/delete_batch`,
    method: 'post',
    data
  })
}

export function deleteSchoolsImageApi(data) {
  return service({
    url: `/schools/school/gallery/delete`,
    method: 'post',
    data
  })
}

export function updateSchoolsImageApi(data) {
  return service({
    url: `/schools/school/gallery/update`,
    method: 'post',
    data
  })
}

export function updateSchoolsImageOrderApi(data) {
  return service({
    url: `/schools/school/gallery/order`,
    method: 'post',
    data
  })
}

/* log */

export function loadLogsApi(data) {
  return service({
    url: `/logs`,
    method: 'post',
    data
  })
}

export function loadlogApi(params) {
  return service({
    url: `/logs/log`,
    method: 'get',
    params
  })
}

export function deleteLogApi(data) {
  return service({
    url: `/logs/log/delete`,
    method: 'post',
    data
  })
}

export function deleteLogBatchApi(data) {
  return service({
    url: `/logs/delete_batch`,
    method: 'post',
    data
  })
}

/**
 * @description: 上传封面
 * @param {*} data
 * @param {*} onUploadProgress
 * @return {*}
 */
export function uploadApi(data, onUploadProgress) {
  return service({
    url: '/upload',
    timeout: UPLOAD_TIMEOUT,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

/**
 * @description: 上传 member gallery
 * @param {*} data
 * @param {*} onUploadProgress
 * @return {*}
 */
export function uploadMemberGalleryApi(data, onUploadProgress) {
  return service({
    url: '/upload/member-images',
    timeout: UPLOAD_TIMEOUT,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

/**
 * @description: 上传 school gallery
 * @param {*} data
 * @param {*} onUploadProgress
 * @return {*}
 */
export function uploadSchoolGalleryApi(data, onUploadProgress) {
  return service({
    url: '/upload/school-images',
    timeout: UPLOAD_TIMEOUT,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}
