import PropTypes from 'prop-types'

// @mui
import { List, Stack } from '@mui/material'

import RoleBasedGuard from 'src/auth/RoleBasedGuard'

// locales
import { useLocales } from '../../../locales'
import NavList from './NavList'
//
import { StyledSubheader } from './styles'

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array
}

export default function NavSectionVertical({ data, sx, ...other }) {
  const { translate } = useLocales()

  return (
    <Stack
      sx={sx}
      {...other}
    >
      {data.map((group) => {
        const key = group.subheader || group.items[0].title

        return (
          <RoleBasedGuard
            key={key}
            roles={group.roles}
          >
            <List
              key={key}
              disablePadding
              sx={{ px: 2 }}
            >
              {group.subheader && <StyledSubheader disableSticky>{translate(group.subheader)}</StyledSubheader>}

              {group.items.map((list) => (
                <NavList
                  key={list.title + list.path}
                  data={list}
                  depth={1}
                  hasChild={!!list.children}
                />
              ))}
            </List>
          </RoleBasedGuard>
        )
      })}
    </Stack>
  )
}
