/*
 * @Author: Tom Cruise
 * @Date: 2022-04-14 21:20:50
 * @LastEditTime: 2023-10-08 19:42:47
 * @LastEditors: Tom
 * @Description:
 */
import { forwardRef } from 'react'

import { Slide } from '@mui/material'

const Transition = forwardRef((props, ref) => {
  return (
    <Slide
      ref={ref}
      direction="down"
      {...props}
    />
  )
})

export default Transition
