import PropTypes from 'prop-types'

import { IconButtonAnimate } from '@components/animate'
import Iconify from '@components/iconify'

const SearchInput = ({ handleClickOpen }) => {
  return (
    <IconButtonAnimate onClick={handleClickOpen}>
      <Iconify icon="eva:search-fill" />
    </IconButtonAnimate>
  )
}

SearchInput.propTypes = {
  handleClickOpen: PropTypes.func
}

export default SearchInput
