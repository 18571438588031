// routes
// components
import Iconify from '@/components/iconify'
// config
import { PATH_DASHBOARD } from '@/routes/paths'

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/'
  },

  {
    title: 'Dashboard',
    icon: <Iconify icon="eva:book-open-fill" />,
    path: PATH_DASHBOARD.general.app
  }
]

export default navConfig
