import { memo, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useLocation } from 'react-router-dom'

import SearchDialog from './SearchDialog'
import SearchInput from './SearchInput'

const HeaderSearch = () => {
  const { pathname } = useLocation()

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useHotkeys('shift+s', () => setOpen(true))

  useEffect(() => {
    if (open) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <>
      <SearchInput handleClickOpen={handleClickOpen} />

      <SearchDialog
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

HeaderSearch.propTypes = {}

// 指定 props 的默认值：
HeaderSearch.defaultProps = {}

export default memo(HeaderSearch)
