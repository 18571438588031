import { useLocation } from 'react-router-dom'

// @mui
import { Box, Container, Divider, Grid, IconButton, Link, Stack, Typography } from '@mui/material'

import Iconify from '@components/iconify'
// components
import Logo from '@components/logo'

const _socials = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#1877F2',
    path: 'https://www.facebook.com/caitlyn.kerluke'
  },
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'ant-design:instagram-filled',
    color: '#E02D69',
    path: 'https://www.instagram.com/caitlyn.kerluke'
  },
  {
    value: 'linkedin',
    name: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#007EBB',
    path: 'https://www.linkedin.com/caitlyn.kerluke'
  },
  {
    value: 'twitter',
    name: 'Twitter',
    icon: 'eva:twitter-fill',
    color: '#00AAEC',
    path: 'https://www.twitter.com/caitlyn.kerluke'
  }
]

// ----------------------------------------------------------------------

export default function Footer() {
  const { pathname } = useLocation()

  const isHome = pathname === '/'

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default'
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography
          variant="caption"
          component="div"
        >
          © All rights reserved
          <br /> made by &nbsp;
          <Link href=""> Tom</Link>
        </Typography>
      </Container>
    </Box>
  )

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default'
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between'
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left'
            }
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ mb: 3 }}
          >
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid
            item
            xs={8}
            md={3}
          >
            <Typography
              variant="body2"
              sx={{ pr: { md: 5 } }}
            >
              Roster
            </Typography>

            <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 }
              }}
            >
              {_socials.map((social) => (
                <IconButton key={social.name}>
                  <Iconify icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 10,
            pb: 5,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2023. All rights reserved
        </Typography>
      </Container>
    </Box>
  )

  return isHome ? simpleFooter : mainFooter
}
