/*
 * @Author: Tom Cruise
 * @Date: 2022-04-02 17:39:57
 * @LastEditTime: 2023-10-08 21:17:06
 * @LastEditors: Tom
 * @Description:
 */
import service from '@utils/request'

const apiRoot = `/quick-search`

/**
 * @description: quick-search
 * @param {*}
 * @return {*}
 */

export function loadQuickSearchMembersApi(params) {
  return service({
    url: `${apiRoot}`,
    method: 'get',
    params
  })
}

export function loadQuickSearchLogApi(params) {
  return service({
    url: `${apiRoot}/log`,
    method: 'get',
    params
  })
}

export function loadQuickSearchLogSaveApi(params) {
  return service({
    url: `${apiRoot}/log/save-favourite`,
    method: 'get',
    params
  })
}

export function loadQuickSearchLogDeleteApi(params) {
  return service({
    url: `${apiRoot}/log/delete`,
    method: 'get',
    params
  })
}

/**
 * @description: 清理log 防止数据过的
 * @param {*} params
 * @return {*}
 */
export function loadQuickSearchLogCountApi(params) {
  return service({
    url: `${apiRoot}/log/count-clear`,
    method: 'get',
    params
  })
}
