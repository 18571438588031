import { Navigate, useRoutes } from 'react-router-dom'

// auth
import AuthGuard from '../auth/AuthGuard'
import GuestGuard from '../auth/GuestGuard'
// config
import { PATH_AFTER_LOGIN } from '../config'
import CompactLayout from '../layouts/compact'
import DashboardLayout from '../layouts/dashboard'
// layouts
import MainLayout from '../layouts/main'
//
import {
	Demo,
  GeneralAppPage,
  HomePage,
  KindergartenProfile,
  KindergartenStudentList,
  KindergartenStudentProfile, // Auth
  KindergartenTeacherList,
  KindergartenTeacherProfile,
  LogPage,
  LogProfilePage,
  LoginPage, //
  MaintenancePage,
  Page403,
  Page404, //
  Page500,
  PrimaryGradesProfile,
  PrimaryGradesStudentList,
  PrimaryGradesStudentProfile, // Auth
  PrimaryGradesTeacherList,
  PrimaryGradesTeacherProfile, // Dashboard: User
  ReadListPage,
	ReadPage,
  SchoolCreatePage,
  SchoolEditPage,
  SchoolListPage,
  SchoolProfilePage,
  UserCreatePage,
  UserEditPage,
  UserListPage,
  UserProfilePage
} from './elements'

export const routes = [
  // Auth
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <LoginPage />
          </GuestGuard>
        )
      }
    ]
  },

  // Dashboard
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <Navigate
            to={PATH_AFTER_LOGIN}
            replace
          />
        ),
        index: true
      },
      { path: 'app', element: <GeneralAppPage /> },
      {
        path: 'kindergarten',
        meta: {
          roles: ['admin', 'kindergarten']
        },
        children: [
          {
            element: (
              <Navigate
                to="/dashboard/kindergarten/profile"
                replace
              />
            ),
            index: true
          },
          { path: 'profile', element: <KindergartenProfile /> },
          {
            path: 'teacher',
            children: [
              {
                element: (
                  <Navigate
                    to="/dashboard/kindergarten/teacher/list"
                    replace
                  />
                ),
                index: true
              },
              { path: 'list', element: <KindergartenTeacherList /> },
              { path: ':id/:name/profile', element: <KindergartenTeacherProfile /> }
            ]
          },
          {
            path: 'student',
            children: [
              {
                element: (
                  <Navigate
                    to="/dashboard/kindergarten/student/list"
                    replace
                  />
                ),
                index: true
              },
              { path: 'list', element: <KindergartenStudentList /> },
              { path: ':id/:name/profile', element: <KindergartenStudentProfile /> }
            ]
          }
        ]
      },
      {
        path: 'primary-grades',
        meta: {
          roles: ['admin', 'primarySchool']
        },
        children: [
          {
            element: (
              <Navigate
                to="/dashboard/primary-grades/profile"
                replace
              />
            ),
            index: true
          },
          { path: 'profile', element: <PrimaryGradesProfile /> },
          {
            path: 'teacher',
            children: [
              {
                element: (
                  <Navigate
                    to="/dashboard/primary-grades/teacher/list"
                    replace
                  />
                ),
                index: true
              },
              { path: 'list', element: <PrimaryGradesTeacherList /> },
              { path: ':id/:name/profile', element: <PrimaryGradesTeacherProfile /> }
            ]
          },
          {
            path: 'student',
            children: [
              {
                element: (
                  <Navigate
                    to="/dashboard/primary-grades/student/list"
                    replace
                  />
                ),
                index: true
              },
              { path: 'list', element: <PrimaryGradesStudentList /> },
              { path: ':id/:name/profile', element: <PrimaryGradesStudentProfile /> }
            ]
          },
          {
            path: 'read',
            element: <ReadPage />
          }
        ]
      },

      {
        path: 'user',
        meta: {
          roles: ['admin']
        },
        children: [
          {
            element: (
              <Navigate
                to="/dashboard/user/list"
                replace
              />
            ),
            index: true
          },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: ':id/:name/profile', element: <UserProfilePage /> },
          { path: ':id/:name/read', element: <ReadListPage /> }
        ]
      },
      {
        path: 'school',
        meta: {
          roles: ['admin']
        },
        children: [
          {
            element: (
              <Navigate
                to="/dashboard/school/list"
                replace
              />
            ),
            index: true
          },
          { path: 'list', element: <SchoolListPage /> },
          { path: 'new', element: <SchoolCreatePage /> },
          { path: ':id/edit', element: <SchoolEditPage /> },
          { path: ':id/profile', element: <SchoolProfilePage /> }
        ]
      },
      {
        path: 'log',
        meta: {
          roles: ['admin']
        },
        children: [
          {
            element: (
              <Navigate
                to="/dashboard/log/list"
                replace
              />
            ),
            index: true
          },
          { path: 'list', element: <LogPage /> },
          { path: ':id/profile', element: <LogProfilePage /> }
        ]
      }
    ]
  },

  // Main Routes
  {
    element: <MainLayout />,
    children: [{ element: <HomePage />, index: true }]
  },

  {
    element: <CompactLayout />,
    children: [
      { path: 'maintenance', element: <MaintenancePage /> },
			{ path: 'demo', element: <Demo /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> }
    ]
  },
  {
    path: '*',
    element: (
      <Navigate
        to="/404"
        replace
      />
    )
  }
]

export default function Router() {
  return useRoutes(routes)
}
