import { groupBy, sortBy } from 'lodash'

import { ROLES } from '@/utils/constant'

/**
 * @description: //生成从minNum到maxNum的随机数
 * @param {*} min
 * @param {*} max
 * @return {*}
 */
export const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * @description:
 * @param {*} role
 * @return {*} level
 */
export const getLevelByRole = (role) => {
  const level = ROLES.findIndex((item) => item === role)
  return level
}

/**
 * @description:
 * @param {*} list
 * @return {*}
 * [
  {
    name: '2022',
    data: [
      0.5,  1,    1,    null,
      null, null, null, null,
      null, null, null, null
    ]
  },
  {
    name: '2023',
    data: [
      1,    null, null,
      null, null, null,
      null, null, null,
      null, null, null
    ]
  }
]
 */
export const formatReadChart = (list) => {
  const dataList = list.map((item) => {
    return {
      dataTime: item.dateTime,
      wordNumber: item.wordNumber,
      year: item.dateTime.split('-')[0],
      month: item.dateTime.split('-')[1]
    }
  })

  const data = groupBy(dataList, 'year')

  // 补齐每年的数据都是12个月的
  Object.keys(data).map((year) => {
    const months = []

    // 获取已有数据中的月份
    for (let i = 0; i < data[year].length; i++) {
      const month = parseInt(data[year][i].dataTime.split('-')[1], 10)
      months.push(month)
    }

    // 补齐缺失的月份数据
    for (let i = 1; i <= 12; i++) {
      if (!months.includes(i)) {
        const newData = {
          dataTime: `${year}-${i.toString().padStart(2, '0')}`,
          wordNumber: null, // 可以根据需求设置默认值
          year,
          month: i
        }

        data[year].push(newData)
      }
    }
    return null
  })

  Object.keys(data).map((year) => {
    data[year] = sortBy(data[year], 'month')
    return null
  })
  // for (const year in data) {
  //   data[year] = sortBy(data[year], 'dateTime')
  // }

  const dataChart = []
  console.log('data===', data)
  Object.keys(data).map((year) => {
    const d = []
    for (let i = 0; i < data[year].length; i++) {
      d.push(data[year][i].wordNumber)
    }
    dataChart.push({ name: year, data: d })
    return null
  })
  return dataChart

  // for (const year in data) {
  //   const d = []
  //   for (let i = 0; i < data[year].length; i++) {
  //     d.push(data[year][i].wordNumber)
  //   }
  //   dataChart.push({ name: year, data: d })
  // }
}

/**
 * @description: 小数点后保留n位有效数字
 * @param {*} num
 * @param {*} n
 * @return {*}
 */
export const formatFloat = (num, n) => {
  let f = parseFloat(num)
  if (Number.isNaN(f)) {
    return false
  }
  f = Math.round(num * 10 ** n) / 10 ** n // n 幂
  let s = f.toString()
  let rs = s.indexOf('.')
  // 判定如果是整数，增加小数点再补0
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length <= rs + n) {
    s += '0'
  }
  return s
}

export const getRealImage = (imageUrl) => {
  return process.env.NODE_ENV === 'development' ? `http://baby.hisread.com${imageUrl}` : imageUrl
}

// 重新记录数组顺序
export const reOrder = (list, startIndex, endIndex) => {
  const result = Array.from(list)

  const [removed] = result.splice(startIndex, 1)

  result.splice(endIndex, 0, removed)
  return result
}
export default {}
