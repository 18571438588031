// routes
// components
import SvgColor from '@components/svg-color'

import { PATH_DASHBOARD } from '@/routes/paths'

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
)

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  kindergarten: icon('teddy-bear'),
  primarySchool: icon('school-bag'),
  school: icon('school'),
  log: icon('log')
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [{ title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }]
  },
  {
    subheader: 'school',
    items: [
      // 幼儿园
      {
        title: 'kindergarten',
        roles: ['admin', 'kindergarten'],
        path: PATH_DASHBOARD.school.kindergarten.root,
        icon: ICONS.kindergarten,
        children: [
          { title: 'profile', path: PATH_DASHBOARD.school.kindergarten.profile },
          {
            title: 'teacher',
            path: PATH_DASHBOARD.school.kindergarten.teacher.list
          },
          {
            title: 'student',
            path: PATH_DASHBOARD.school.kindergarten.student.list
          }
        ]
      },
      {
        title: 'primary school',
        roles: ['admin', 'primarySchool'],
        path: PATH_DASHBOARD.school.primarySchool.root,
        icon: ICONS.primarySchool,
        children: [
          { title: 'profile', path: PATH_DASHBOARD.school.primarySchool.profile },
          {
            title: 'teacher',
            path: PATH_DASHBOARD.school.primarySchool.teacher.list
          },
          {
            title: 'student',
            path: PATH_DASHBOARD.school.primarySchool.student.list
          },
          {
            title: 'read',
            path: PATH_DASHBOARD.school.primarySchool.read.root
          }
        ]
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    roles: ['admin'],
    items: [
      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.new }
        ]
      },
      {
        title: 'school',
        path: PATH_DASHBOARD.schools.root,
        icon: ICONS.school,
        children: [
          { title: 'list', path: PATH_DASHBOARD.schools.list },
          { title: 'create', path: PATH_DASHBOARD.schools.new }
        ]
      },
      {
        title: 'log',
        path: PATH_DASHBOARD.log.list,
        icon: ICONS.log,
      }
    ]
  }
]

export default navConfig
