// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
}

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500'
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  school: {
    kindergarten: {
      root: path(ROOTS_DASHBOARD, '/kindergarten'),
      profile: path(ROOTS_DASHBOARD, '/kindergarten/profile'),
      teacher: {
        list: path(ROOTS_DASHBOARD, '/kindergarten/teacher/list'),
        view: (id, name) => path(ROOTS_DASHBOARD, `/kindergarten/teacher/${id}/${name}/profile`)
      },
      student: {
        list: path(ROOTS_DASHBOARD, '/kindergarten/student/list'),
        view: (id, name) => path(ROOTS_DASHBOARD, `/kindergarten/student/${id}/${name}/profile`)
      }
    },
    primarySchool: {
      root: path(ROOTS_DASHBOARD, '/primary-grades'),
      profile: path(ROOTS_DASHBOARD, '/primary-grades/profile'),
      teacher: {
        list: path(ROOTS_DASHBOARD, '/primary-grades/teacher/list'),
        view: (id, name) => path(ROOTS_DASHBOARD, `/primary-grades/teacher/${id}/${name}/profile`)
      },
      student: {
        list: path(ROOTS_DASHBOARD, '/primary-grades/student/list'),
        view: (id, name) => path(ROOTS_DASHBOARD, `/primary-grades/student/${id}/${name}/profile`)
      },
      read: {
        root: path(ROOTS_DASHBOARD, '/primary-grades/read')
      }
    },
    juniorHighschool: {
      root: path(ROOTS_DASHBOARD, '/junior-high-school'),
      profile: path(ROOTS_DASHBOARD, '/junior-high-school/profile'),
      teacher: {
        list: path(ROOTS_DASHBOARD, '/junior-high-school/teacher/list'),
        view: (id, name) => path(ROOTS_DASHBOARD, `/junior-high-school/teacher/${id}/${name}/profile`)
      },
      student: {
        list: path(ROOTS_DASHBOARD, '/junior-high-school/student/list'),
        view: (id, name) => path(ROOTS_DASHBOARD, `/junior-high-school/student/${id}/${name}/profile`)
      }
    },
    seniorHighschool: {
      root: path(ROOTS_DASHBOARD, '/senior-high-school'),
      profile: path(ROOTS_DASHBOARD, '/senior-high-school/profile'),
      teacher: {
        list: path(ROOTS_DASHBOARD, '/senior-high-school/teacher/list'),
        view: (id, name) => path(ROOTS_DASHBOARD, `/senior-high-school/teacher/${id}/${name}/profile`)
      },
      student: {
        list: path(ROOTS_DASHBOARD, '/senior-high-school/student/list'),
        view: (id, name) => path(ROOTS_DASHBOARD, `/senior-high-school/student/${id}/${name}/profile`)
      }
    },
    university: {
      root: path(ROOTS_DASHBOARD, '/university'),
      profile: path(ROOTS_DASHBOARD, '/university/profile'),
      teacher: {
        list: path(ROOTS_DASHBOARD, '/university/teacher/list'),
        view: (id, name) => path(ROOTS_DASHBOARD, `/university/teacher/${id}/${name}/profile`)
      },
      student: {
        list: path(ROOTS_DASHBOARD, '/university/student/list'),
        view: (id, name) => path(ROOTS_DASHBOARD, `/university/student/${id}/${name}/profile`)
      }
    }
  },

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    view: (id, name) => path(ROOTS_DASHBOARD, `/user/${id}/${name}/profile`),
    read: (id, name) => path(ROOTS_DASHBOARD, `/user/${id}/${name}/read`)
  },
  schools: {
    root: path(ROOTS_DASHBOARD, '/school'),
    new: path(ROOTS_DASHBOARD, '/school/new'),
    list: path(ROOTS_DASHBOARD, '/school/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/school/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/school/${id}/profile`)
  },
  log: {
    root: path(ROOTS_DASHBOARD, '/log'),
    list: path(ROOTS_DASHBOARD, '/log/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/log/${id}/profile`)
  }
}
