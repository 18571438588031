import { useTranslation } from 'react-i18next'

import { Typography, useTheme } from '@mui/material'

import Iconify from '@/components/iconify'

const SearchTips = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Typography
      component="div"
      variant="subtitle1"
      sx={{
        bgcolor: 'grey.200',
        fontWeight: 'normal',
        color: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        fontSize: theme.typography.pxToRem(13),
        display: 'flex',
        alignItems: 'center',
        p: 1,
        mx: 2,
        my: 2
      }}
    >
      <Iconify
        icon="ic:twotone-contact-support"
        width={18}
        sx={{
          mr: 0.8,
          fontSize: theme.typography.pxToRem(18)
        }}
      />

      {t('Start typing to see the search results...')}
    </Typography>
  )
}

export default SearchTips
