import merge from 'lodash/merge'
import PropTypes from 'prop-types'

import { useMemo } from 'react'

import { CssBaseline } from '@mui/material'
// @mui
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles'

//
import { useSettingsContext } from './SettingsContext'

// ----------------------------------------------------------------------

ThemeContrast.propTypes = {
  children: PropTypes.node
}

export default function ThemeContrast({ children }) {
  const outerTheme = useTheme()

  const { themeContrast, themeMode } = useSettingsContext()

  const isLight = themeMode === 'light'

  const isContrastBold = themeContrast === 'bold'

  const themeOptions = useMemo(
    () => ({
      palette: {
        background: {
          ...(isContrastBold && {
            default: isLight ? outerTheme.palette.grey[100] : outerTheme.palette.grey[900]
          })
        }
      },
      components: {
        MuiCard: {
          styleOverrides: {
            ...(isContrastBold && {
              root: {
                boxShadow: outerTheme.customShadows.z4
              }
            })
          }
        }
      }
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLight, themeContrast]
  )

  const theme = createTheme(merge(outerTheme, themeOptions))

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
