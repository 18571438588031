import clsx from 'clsx'
import PropTypes from 'prop-types'

import { useState } from 'react'

import { Dialog, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'

import LoadingProgress from '@components/loadingProgress/LoadingProgress'

import useDebounce from '@hooks/useDebounce'

import { loadQuickSearchMembersApi } from '@/api/apiQuick'
import Transition from '@/components/Transition'

import KeyInterface from './KeyInterface'
import Result from './Result'
import SearchTips from './SearchTips'

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
       max-height: calc(100vh - 64px)
    }
		&.MuiDialog-container-fullScreen {
			& > .MuiDialog-container {
				height: 100%;
				& > .MuiDialog-paperScrollPaper{
					max-height: 100%;
				}
			}

		}
`
)
const SearchDialog = ({ open, onClose }) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [searchResults, setSearchResults] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [dataList, setDataList] = useState([])

  const submitSearch = async (event) => {
    console.log('submitSearch', searchValue)
    event.preventDefault()
    setSearchResults(false)
    // setSearchLoading(true)
    const keyword = searchValue
    await loadQuickSearch({ keyword })
    // await wait(1500)
    // setSearchLoading(false)
    setSearchResults(true)
  }

  const handleQueryChange = useDebounce((event) => {
    event.persist()
    const keyword = event.target.value
    keyword && loadQuickSearch({ keyword })
  }, 300)

  const clickedClearHandle = () => {
    setSearchValue('')
    setSearchResults(false)
  }

  const handleSearchChange = async (event) => {
    event.preventDefault()

    if (event.target.value) {
      console.log('quick ajax')
      // const keyword = event.target.value
      setSearchResults(false)
      setSearchValue(event.target.value)
      // setSearchLoading(true)
      setSearchResults(true)
      handleQueryChange(event)
    } else {
      setSearchValue('')
      setSearchResults(false)
    }
  }

  const loadQuickSearch = async (params) => {
    try {
      setSearchLoading(true)
      let res = { data: { list: [] } }
      if (params.keyword) {
        res = await loadQuickSearchMembersApi(params)
      }
      setDataList(res.data.list)
      setSearchLoading(false)
      setSearchResults(true)
      return res
    } catch (err) {
      setSearchLoading(false)
      console.error(err)
      return null
    }
  }

  const switchScreen = () => {
    setFullScreen(!fullScreen)
  }

  return (
    <DialogWrapper
      className={clsx({ 'MuiDialog-container-fullScreen': fullScreen })}
      fullScreen={fullScreen}
      maxWidth="sm"
      open={open}
      scroll="paper"
      TransitionComponent={Transition}
      fullWidth
      keepMounted
      onClose={onClose}
    >
      <KeyInterface
        clickedClearHandle={clickedClearHandle}
        fullScreen={fullScreen}
        handleSearchChange={handleSearchChange}
        open={open}
        searchValue={searchValue}
        submitSearch={submitSearch}
        switchScreen={switchScreen}
        clear
      />
      <Divider />
      {!searchLoading && <>{!searchResults && <SearchTips />}</>}
      {searchLoading ? (
        <LoadingProgress />
      ) : (
        <>
          {searchResults ? (
            <Result
              dataList={dataList}
              fullScreen={fullScreen}
              onClose={onClose}
            />
          ) : null}
        </>
      )}
    </DialogWrapper>
  )
}

SearchDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}
export default SearchDialog
