import zhCN from 'date-fns/locale/zh-CN'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { zhCN as cn } from '@mui/x-date-pickers/locales'

const LocalizationDateFnsProvider = ({ children }) => {
  const { i18n } = useTranslation()
  console.log('i18n', i18n.language)
  return i18n.language === 'cn' ? (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={cn.components.MuiLocalizationProvider.defaultProps.localeText}
      adapterLocale={zhCN}
    >
      {children}
    </LocalizationProvider>
  ) : (
    <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
  )
}

LocalizationDateFnsProvider.propTypes = {
  children: PropTypes.node
}
export default LocalizationDateFnsProvider
