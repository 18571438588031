import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import Scrollbar from '@components/Scrollbar'
// import NoData from '@components/NoData'
import EmptyContent from '@components/empty-content'

// import RowGroup from './RowGroup'
import Row from './Row'

const Result = ({ dataList, fullScreen, onClose }) => {
  let dataListLen = 0
  dataList.map((item) => {
    const len = item[Object.keys(item)[0]].length
    dataListLen += len
    return null
  })
  return (
    <Box
      sx={{
        height: fullScreen ? 'calc(100vh - 64px)' : 850
      }}
    >
      {dataListLen === 0 ? (
        <EmptyContent
          title="No Data"
          sx={{
            '& span.MuiBox-root': { height: 60 }
          }}
        />
      ) : (
        <Scrollbar>
          <Box sx={{ px: '9px', py: '18px' }}>
            {dataList.map((item) => {
              return (
                <Row
                  key={item.id}
                  member={item}
                  onClose={onClose}
                />
              )
            })}
          </Box>
        </Scrollbar>
      )}
    </Box>
  )
}

Result.propTypes = {
  dataList: PropTypes.array,
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func
}

export default Result
