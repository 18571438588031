import { cloneDeep } from 'lodash'

import { useMemo } from 'react'
import { useRoutes } from 'react-router-dom'

import { routes } from 'src/routes'

import { useAuthContext } from './useAuthContext'

// 通过用户角色筛选路由

const AsyncRoutesByRole = (routes, role) => {
  if (role) {
    return routes
      .map((route) => {
        if (route.meta) {
          const { roles } = route.meta
          // console.log('Array.isArray(roles)', Array.isArray(roles))
          // console.log('!roles.includes(role)', !roles.includes(role), role, roles)
          if (Array.isArray(roles) && !roles.includes(role)) return null
        }

        if (!route.children) return route
        route.children = AsyncRoutesByRole(route.children, role)
        return route
      })
      .filter((i) => i !== null)
  }
  return routes
}

const RoleGuardRouter = () => {
  const { user } = useAuthContext()
  console.log('user.role', user?.role)
  const filterRoutes = useMemo(() => {
    const routesClone = cloneDeep(routes)
    const routesOk = AsyncRoutesByRole(routesClone, user?.role)
    // console.log('routesOk', routesOk, routes)
    return routesOk
  }, [user?.role])
  // console.log('filterRoutes', filterRoutes)

  return useRoutes(filterRoutes)
}

export default RoleGuardRouter
