import { LazyMotion, m } from 'framer-motion'
import PropTypes from 'prop-types'

// ----------------------------------------------------------------------

// eslint-disable-next-line import/extensions
const loadFeatures = () => import('./features.jsx').then((res) => res.default)

MotionLazyContainer.propTypes = {
  children: PropTypes.node
}

export default function MotionLazyContainer({ children }) {
  return (
    <LazyMotion
      strict
      features={loadFeatures}
    >
      <m.div style={{ height: '100%' }}> {children} </m.div>
    </LazyMotion>
  )
}
