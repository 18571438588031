import PropTypes from 'prop-types'

import { useRef } from 'react'

import { AppBar, Box, Container, Link, Toolbar } from '@mui/material'
// @mui
import { useTheme } from '@mui/material/styles'

import Label from '@components/label'
// components
import Logo from '@components/logo'

// config
import { HEADER } from '@/config'
// hooks
import useOffSetTop from '@/hooks/useOffSetTop'
import useResponsive from '@/hooks/useResponsive'
// routes
import { PATH_DASHBOARD } from '@/routes/paths'
// utils
import { bgBlur } from '@/utils/cssStyles'

//
import navConfig from './nav/config'
import NavDesktop from './nav/desktop'
import NavMobile from './nav/mobile'

// ----------------------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null)

  const theme = useTheme()

  const isDesktop = useResponsive('up', 'md')

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP)

  return (
    <AppBar
      ref={carouselRef}
      color="transparent"
      sx={{ boxShadow: 0 }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16
            }
          })
        }}
      >
        <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Logo />

          <Link
            href={PATH_DASHBOARD.root}
            rel="noopener"
            underline="none"
            sx={{ ml: 1 }}
          >
            <Label color="info"> Roster </Label>
          </Link>

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && (
            <NavDesktop
              isOffset={isOffset}
              data={navConfig}
            />
          )}

          {!isDesktop && (
            <NavMobile
              isOffset={isOffset}
              data={navConfig}
            />
          )}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  )
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object
}

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx
      }}
      {...other}
    />
  )
}
