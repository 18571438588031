import PropTypes from 'prop-types'

import { Fragment } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Avatar, Box, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { alpha, styled } from '@mui/material/styles'

import { useAuthContext } from '@/auth/useAuthContext'
import Iconify from '@/components/iconify'
import { PATH_DASHBOARD } from '@/routes/paths'
import { getLevelByRole } from '@/utils'

// import RowActor from './section/RowActor'

const ListButton = styled(Box)(
  ({ theme }) => `
      background-color: transparent;
      color:  ${theme.palette.grey[500]};
      transition: ${theme.transitions.create(['all'])};
      border: ${alpha(theme.palette.grey[500], 0.32)} solid 1px;
      border-radius: ${Number(theme.shape.borderRadius) * 1}px;
      padding: ${theme.spacing(1)};
      margin: ${theme.spacing(1, 0)};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div > .MuiAvatar-root {
        transition: ${theme.transitions.create(['all'])};
      }

      &:hover {
        background-color: ${alpha(theme.palette.primary.main, 0.08)};
        color:  ${theme.palette.primary.main};
        border-color: ${alpha(theme.palette.primary.main, 0.3)};

        & > div > .MuiAvatar-root {
          color:  ${theme.palette.primary.main};
        }
      }
`
)

const Row = ({ member, onClose }) => {
  const { user } = useAuthContext()
  const { id, name, avatarCartoonUrl } = member
  const { role } = user
  const objPath = {
    0: PATH_DASHBOARD.school.kindergarten,
    1: PATH_DASHBOARD.school.primarySchool,
    2: PATH_DASHBOARD.school.juniorHighschool,
    3: PATH_DASHBOARD.school.seniorHighschool
  }

  const memberTypesObj = {
    1: 'student',
    2: 'teacher'
  }

  let linkTo = ''
  const level = getLevelByRole(role)

  if (user?.role === 'admin') {
    linkTo = PATH_DASHBOARD.user.view(id, name)
  } else {
    linkTo = objPath[level][memberTypesObj[member.memberType]].view(id, name)
  }

  return (
    <Fragment key={id}>
      <ListButton
        to={linkTo}
        component={RouterLink}
        onClick={onClose}
        sx={{
          textDecoration: 'none'
        }}
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <Avatar
            src={avatarCartoonUrl}
            sx={{
              width: 30,
              height: 30,
              opacity: 1,
              mr: 1
            }}
          />

          <Typography>{name}</Typography>
        </Box>
        <IconButton color="primary">
          <Iconify
            icon="ic:twotone-keyboard-arrow-right"
            width={20}
          />
        </IconButton>
      </ListButton>
    </Fragment>
  )
}

Row.propTypes = {
  member: PropTypes.object,
  onClose: PropTypes.func
}

export default Row
