// routes
import { TOKEN_KEY } from '@utils/constant'

import service from '@utils/request'

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false
  }
  return true
}

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken)

    service.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem(TOKEN_KEY)

    delete service.defaults.headers.common.Authorization
  }
}
