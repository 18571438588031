import PropTypes from 'prop-types'

import { forwardRef } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// @mui
import { Box } from '@mui/material'

import useDeviceDetect from '@/hooks/useDeviceDetect'

//
import getRatio from './getRatio'

// ----------------------------------------------------------------------
// 移动端快速滑动 react-lazy-load-image-component 组件会出现性能问题
// ----------------------------------------------------------------------

const ImageLazyStyle = ({ ...rest }) => {
  return (
    <span
      className="wrapper lazy-load-image-background blur lazy-load-image-loaded"
      style={{ color: 'transparent', display: 'inline-block' }}
    >
      <img
        alt=""
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
        {...rest}
      />
    </span>
  )
}

const Image = forwardRef(({ ratio, disabledEffect = false, effect = 'blur', sx, ...rest }, ref) => {
  const { isMobile } = useDeviceDetect()
  const content = isMobile ? (
    <ImageLazyStyle {...rest} />
  ) : (
    <Box
      component={LazyLoadImage}
      wrapperClassName="wrapper"
      effect={disabledEffect ? undefined : effect}
      placeholderSrc={disabledEffect ? '/assets/transparent.png' : '/assets/placeholder.svg'}
      sx={{ width: 1, height: 1, objectFit: 'cover' }}
      {...rest}
    />
  )

  // if (isMobile) {
  //   /*
  // 	移动端图片过多的情况下，快速滑动 react-lazy-load-image-component 组件会出现严重性能问题，所以移动端使用原生img
  // 	*/
  //   return (
  //     <img
  //       alt=""
  //       {...rest}
  //     />
  //   )
  // }

  if (ratio) {
    return (
      <Box
        ref={ref}
        component="span"
        sx={{
          width: 1,
          lineHeight: 1,
          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          pt: getRatio(ratio),
          '& .wrapper': {
            top: 0,
            left: 0,
            width: 1,
            height: 1,
            position: 'absolute',
            backgroundSize: 'cover !important'
          },
          ...sx
        }}
      >
        {content}
      </Box>
    )
  }

  return (
    <Box
      ref={ref}
      component="span"
      sx={{
        lineHeight: 1,
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        '& .wrapper': {
          width: 1,
          height: 1,
          backgroundSize: 'cover !important'
        },
        ...sx
      }}
    >
      {content}
    </Box>
  )
})

Image.propTypes = {
  sx: PropTypes.object,
  effect: PropTypes.string,
  disabledEffect: PropTypes.bool,
  ratio: PropTypes.oneOf(['4/3', '3/4', '6/4', '4/6', '16/9', '9/16', '21/9', '9/21', '1/1'])
}

export default Image
