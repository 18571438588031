import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import Qs from 'qs'

import { TOKEN_KEY } from '@utils/constant'

// import useAuth from '../hooks/useAuth'

function generateReqKey(config) {
  const { method, url, params, data } = config
  return [method, url, Qs.stringify(params), Qs.stringify(data)].join('&')
}

const pendingRequest = new Map()
function addPendingRequest(config) {
  const requestKey = generateReqKey(config)
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel)
      }
    })
}

function removePendingRequest(config) {
  const requestKey = generateReqKey(config)
  if (pendingRequest.has(requestKey)) {
    const cancelToken = pendingRequest.get(requestKey)
    cancelToken(requestKey)
    pendingRequest.delete(requestKey)
  }
}

const service = axios.create({
  timeout: 30000,
  baseURL: import.meta.env.VITE_API_ROOT
  // `transformRequest` allows changes to the request data before it is sent to the server
  // This is only applicable for request methods 'PUT', 'POST', 'PATCH' and 'DELETE'
  // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
  // FormData or Stream
  // You may modify the headers object.
})

console.log('VITE_API_ROOT', import.meta.env.VITE_API_ROOT)

service.interceptors.request.use(
  (config) => {
    removePendingRequest(config) // 检查是否存在重复请求，若存在则取消已发的请求
    addPendingRequest(config) // 把当前请求信息添加到pendingRequest对象中
    if (config.baseURL) {
      axios.defaults.baseURL = config.baseURL
    }
    // Content-Type 默认值
    const defaultHeaders = {
      'Content-Type': 'application/json;charset=UTF-8'
    }
    config.headers = config.headers || {}
    // 设置multipart/form-data请求格式
    if (config.data instanceof FormData) {
      Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' })
    } else if (config.method !== 'get') {
      // `transformRequest` 允许在向服务器发送前，修改请求数据
      // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
      // console.log('config', config)
      config.headers = {
        ...defaultHeaders
      }
      config.transformRequest = [
        function fun(data) {
          if (config.headers['Content-Type'] === 'application/json;charset=UTF-8') {
            console.log('=====', data)
            return JSON.stringify(data)
          }
          return Qs.stringify(data)
        }
      ]
    } else {
      config.headers['Cache-Control'] = 'no-cache'
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  (response) => {
    removePendingRequest(response.config) // 从pendingRequest对象中移除请求
    const res = response.data
    if (res.code === 999) {
      console.log('登录超时')
      // useAuth().logout()
      localStorage.removeItem(TOKEN_KEY)
      delete service.defaults.headers.common.Authorization
      delete service.defaults.headers.common['X-Token']
      // return Promise.reject(new Error('登录超时'))
      window.location.pathname = '/'
      // to re-login
    } else if (res.code !== 0) {
      console.log('res', res)
    }
    return res
  },
  (error) => {
    removePendingRequest(error.config || {}) // 从pendingRequest对象中移除请求
    if (axios.isCancel(error)) {
      console.log(`已取消的重复请求：`, error.message)
    } else {
      // 添加异常处理
      enqueueSnackbar(`Server Error 🐛 ${error.response?.data?.msg || error.message}`, { variant: 'error' })

      console.log(`err${error}`)
    }
    return Promise.reject(error)
  }
)
export default service
