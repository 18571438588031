// routes
import { MotionLazyContainer } from '@components/animate'
// components
import { ThemeSettings } from '@components/settings'
import SnackbarProvider from '@components/snackbar'

import RoleGuardRouter from '@/auth/RoleGuardRouter'



// locales
import ThemeLocalization from './locales'
// import Router from './routes'
// theme
import ThemeProvider from './theme'

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ThemeLocalization>
            <SnackbarProvider>
              <RoleGuardRouter />
            </SnackbarProvider>
          </ThemeLocalization>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  )
}
