import PropTypes from 'prop-types'

import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Card, InputBase, Typography, styled } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { alpha, useTheme } from '@mui/material/styles'

import Iconify from '@/components/iconify'

const wait = (time) => new Promise((res) => setTimeout(res, time))

const SearchInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(2)};
    width: 100%;
`
)

const KeyInterface = ({
  submitSearch,
  searchValue,
  handleSearchChange,
  open,
  switchScreen,
  clickedClearHandle,
  fullScreen,
  clear = false
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const inputRef = useRef()

  const handleSwitchScreen = () => {
    console.log('test log')
    switchScreen()
  }

  useEffect(() => {
    async function waitFocus() {
      await wait(500)
      inputRef.current?.focus()
    }
    if (open) {
      waitFocus()
    }
  }, [open])

  return (
    <Box>
      <form onSubmit={submitSearch}>
        <Box
          alignItems="center"
          display="flex"
        >
          <Box
            alignItems="center"
            display="flex"
            flexGrow={1}
          >
            <Iconify
              icon="ic:twotone-search"
              width={24}
              sx={{
                ml: 2,
                color: theme.palette.primary.main
              }}
            />

            <SearchInputWrapper
              inputRef={inputRef}
              placeholder={t('Search name here...')}
              value={searchValue}
              endAdornment={
                clear &&
                searchValue && (
                  <IconButton
                    color="primary"
                    onClick={clickedClearHandle}
                  >
                    <Iconify
                      icon="ic:baseline-clear"
                      width={20}
                    />
                  </IconButton>
                )
              }
              autoFocus
              fullWidth
              onChange={handleSearchChange}
            />
          </Box>
          <Card
            sx={{
              ml: 'auto',
              mr: 2,
              py: 0.5,
              px: 1,
              background: alpha(theme.palette.common.black, 0.1)
            }}
          >
            <Typography
              color="text.secondary"
              fontWeight="bold"
              variant="body2"
            >
              esc
            </Typography>
          </Card>

          <IconButton
            color="primary"
            sx={{ mr: 2 }}
            onClick={handleSwitchScreen}
          >
            {fullScreen ? (
              <Iconify
                icon="ic:baseline-close-fullscreen"
                width={24}
              />
            ) : (
              <Iconify
                icon="ic:baseline-fullscreen"
                width={24}
              />
            )}
          </IconButton>
        </Box>
      </form>
    </Box>
  )
}

KeyInterface.propTypes = {
  submitSearch: PropTypes.func,
  searchValue: PropTypes.string,
  handleSearchChange: PropTypes.func,
  open: PropTypes.bool,
  fullScreen: PropTypes.bool,
  switchScreen: PropTypes.func,
  clickedClearHandle: PropTypes.func,
  clear: PropTypes.bool
}

export default KeyInterface
