// i18n
import VConsole from 'vconsole'

import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

// @mui
import ScrollToTop from '@components/scroll-to-top'
// components
import { SettingsProvider } from '@components/settings'

// ----------------------------------------------------------------------
import setupLocatorUI from '@locator/runtime'

import App from './App'
import LocalizationDateFnsProvider from './LocalizationDateFnsProvider'
// Check our docs
// https://docs.minimals.cc/authentication/js-version
import { AuthProvider } from './auth/JwtContext'
import './locales/i18n'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// lightbox
import 'react-image-lightbox/style.css'
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
// editor
import 'react-quill/dist/quill.snow.css'
// scroll bar
import 'simplebar/src/simplebar.css'
import 'slick-carousel/slick/slick-theme.css'
// slick-carousel
import 'slick-carousel/slick/slick.css'

// ----------------------------------------------------------------------

if (process.env.NODE_ENV === 'development') {
  setupLocatorUI()
  // eslint-disable-next-line unused-imports/no-unused-vars
  const mobile = Boolean(
    window.navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
  )
  if (mobile) {
		// eslint-disable-next-line
    const vConsole = new VConsole({ theme: 'dark' })
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <AuthProvider>
    <HelmetProvider>
      <LocalizationDateFnsProvider
      // dateAdapter={AdapterDateFns}
      // localeText={cn.components.MuiLocalizationProvider.defaultProps.localeText}
      // adapterLocale={zhCN}
      >
        <SettingsProvider>
          <BrowserRouter>
            <ScrollToTop />
            <App />
          </BrowserRouter>
        </SettingsProvider>
      </LocalizationDateFnsProvider>
    </HelmetProvider>
  </AuthProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
