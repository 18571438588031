import { Suspense, lazy } from 'react'

// components
import LoadingScreen from '@components/loading-screen'

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
)

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')))

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/user/Profile')))
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/user/List')))
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/user/Create')))
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/user/Edit')))

// Read 

export const ReadPage = Loadable(lazy(() => import('../pages/dashboard/read/Read')))
export const ReadListPage = Loadable(lazy(() => import('../pages/dashboard/user/ReadMonth/List')))

// DASHBOARD: Schools
export const SchoolProfilePage = Loadable(lazy(() => import('../pages/dashboard/schools/SchoolProfile')))
export const SchoolListPage = Loadable(lazy(() => import('../pages/dashboard/schools/SchoolList/SchoolList')))
export const SchoolCreatePage = Loadable(lazy(() => import('../pages/dashboard/schools/SchoolCreate')))
export const SchoolEditPage = Loadable(lazy(() => import('../pages/dashboard/schools/SchoolEdit')))

// School
export const KindergartenProfile = Loadable(lazy(() => import('../pages/dashboard/school/kindergarten/Profile')))
export const KindergartenTeacherList = Loadable(
  lazy(() => import('../pages/dashboard/school/kindergarten/teacher/List'))
)




export const KindergartenTeacherProfile = Loadable(
  lazy(() => import('../pages/dashboard/school/kindergarten/teacher/Profile'))
)

export const KindergartenStudentList = Loadable(
  lazy(() => import('../pages/dashboard/school/kindergarten/student/List'))
)

export const KindergartenStudentProfile = Loadable(
  lazy(() => import('../pages/dashboard/school/kindergarten/student/Profile'))
)

export const PrimaryGradesProfile = Loadable(lazy(() => import('../pages/dashboard/school/primarySchool/Profile')))
export const PrimaryGradesTeacherList = Loadable(
  lazy(() => import('../pages/dashboard/school/primarySchool/teacher/List'))
)

export const PrimaryGradesTeacherProfile = Loadable(
  lazy(() => import('../pages/dashboard/school/primarySchool/teacher/Profile'))
)

export const PrimaryGradesStudentList = Loadable(
  lazy(() => import('../pages/dashboard/school/primarySchool/student/List'))
)

export const PrimaryGradesStudentProfile = Loadable(
  lazy(() => import('../pages/dashboard/school/primarySchool/student/Profile'))
)

export const LogPage = Loadable(lazy(() => import('../pages/dashboard/log/List')))
export const LogProfilePage = Loadable(lazy(() => import('../pages/dashboard/log/Profile')))
// DASHBOARD: APP
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/app/GeneralAppPage')))

// MAIN
export const Demo = Loadable(lazy(() => import('../pages/Demo')))
export const Page500 = Loadable(lazy(() => import('../pages/Page500')))
export const Page403 = Loadable(lazy(() => import('../pages/Page403')))
export const Page404 = Loadable(lazy(() => import('../pages/Page404')))
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')))
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')))
