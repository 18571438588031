import PropTypes from 'prop-types'

import { useMemo } from 'react'

// @mui
import { CssBaseline } from '@mui/material'
import { ThemeProvider as MUIThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'

// components
import { useSettingsContext } from '@components/settings'

import customShadows from './customShadows'
import GlobalStyles from './globalStyles'
import componentsOverride from './overrides'
//
import palette from './palette'
import shadows from './shadows'
import typography from './typography'

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node
}

export default function ThemeProvider({ children }) {
  const { themeMode, themeDirection } = useSettingsContext()

  const themeOptions = useMemo(
    () => ({
      palette: palette(themeMode),
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode)
    }),
    [themeDirection, themeMode]
  )

  const theme = createTheme(themeOptions)

  theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
