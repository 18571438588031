// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  demo: {
    title: `Chinese`
  },
  docs: {
    hi: `你好`,
    description: `需要帮忙？\n 请查看我们的文档.`,
    documentation: `文件`
  },
  app: `App`,
  user: `用户`,
  list: `列表`,
  edit: `编辑`,
  shop: `商店`,
  blog: `博客`,
  post: `邮政`,
  mail: `电子邮件`,
  chat: `聊天`,
  cards: `牌`,
  posts: `帖子`,
  create: `创建`,
  kanban: `登机`,
  general: `一般的`,
  banking: `银行业`,
  booking: `预订`,
  profile: `简介`,
  account: `帐户`,
  product: `产品`,
  invoice: `发票`,
  details: `细节`,
  checkout: `退房`,
  calendar: `日历`,
  analytics: `分析`,
  ecommerce: `电子商务`,
  management: `管理`,
  school: `学校`,
  teacher: `老师`,
  student: `学生`,

  kindergarten: `幼儿园`,
  'primary school': `小学`,
  'Name is required': `姓名是必填项`,
  'Sex is required': `性别是必填项`,
  'Type is required': `类别是必填项`,
  'Level is required': `阶段是必填项`,
  'Avatar is required': `头像是必填项`,
  'Cartoon avatar is required': `卡通头像是必填项`,
  read: '阅读',
  'Top Count All Reader': '总阅读量（万字）',
  'Top Month Reader': '月阅读量（万字）',
  'Top Year Reader': '年阅读量（万字）',
}

export default cn
